.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.webchat__basic-transcript__scrollable {
    @apply scrollbar
}

/* custom-scrollbar.css */
.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: rgba(129, 140, 248) rgb(255, 255, 255);
    /* thumb color and track color */
}

.scrollbar::-webkit-scrollbar {
    width: 12px;
}

.scrollbar::-webkit-scrollbar-track {
    @apply bg-gray-200;
    /* track color */
}

.scrollbar::-webkit-scrollbar-thumb {
    @apply bg-indigo-700 rounded-lg;
    /* thumb color and shape */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-indigo-700;
    /* thumb color on hover */
}